import styled from '@emotion/styled'
import { useRouter } from 'next/router'
import { useCallback } from 'react'

import { LOG_PAGE_ID } from '@/constants/log'
import { ROUTER } from '@/constants/router'
import KeywordButtons from '@/features/shared/components/KeywordButtons'
import { useRecommendKeywords } from '@/hooks/useRecommendKeywords'
import { sendEvent } from '@/libs/mixpanel'
import { COLOR } from '@/styles/color'
import { fontSize } from '@/styles/mixin'

function NotFoundSection() {
  const router = useRouter()
  const { data: recommendkeywords } = useRecommendKeywords()

  const handleClickKeyword = useCallback(
    (keyword: string | boolean) => {
      router.push(`${ROUTER.MAIN}?keyword=${keyword}`)

      sendEvent(LOG_PAGE_ID.SEARCH, 'tap.recommendsearch', { recommendsearch_name: keyword })
    },
    [router]
  )

  return (
    <Container>
      <MessageBox>
        <strong>검색 결과가 없어요.</strong>
        오타가 있는지 확인해 주세요.
        <br />
        다른 검색어로 다시 시도해 보세요.
      </MessageBox>
      <RecommendBox>
        <strong>추천 검색어</strong>
        <KeywordButtons
          keywords={recommendkeywords}
          align="center"
          onClick={handleClickKeyword}
        />
      </RecommendBox>
    </Container>
  )
}

const Container = styled.div`
  padding-top: 72px;
`

const MessageBox = styled.div`
  color: ${COLOR.gray.color.gray[500]};
  text-align: center;

  strong {
    display: block;
    margin-bottom: 12px;
    font-weight: var(--font-weight-bold);
    color: ${COLOR.gray.color.gray[900]};
    ${({ theme }) => fontSize(theme, 18)}
  }
`

const RecommendBox = styled.div`
  margin: 56px auto 0;
  max-width: 259px;
  text-align: center;

  strong {
    display: block;
    font-weight: var(--font-weight-bold);
    color: ${COLOR.gray.color.gray[900]};
    ${({ theme }) => fontSize(theme, 14)}
  }
`

export default NotFoundSection
