import { useQuery } from '@tanstack/react-query'

import { getTicketField } from '@/apis/ticket-field/getTicketField'
import { QUERY_KEY } from '@/constants/key'
import { TICKET_FIELD_NAME } from '@/constants/zendesk'

export const useRecommendKeywords = () => {
  return useQuery({
    queryKey: [QUERY_KEY.RECOMMEND_KEYWORDS],
    queryFn: () => getTicketField(TICKET_FIELD_NAME.RECOMMEND_KEYWORDS, { isProxy: true }),
    select: (data) => {
      return data.ticket_field.custom_field_options
    },
  })
}
