import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useCallback } from 'react'

import SkeletonItem from '@/components/loading/SkeletonItem'
import { COLOR } from '@/styles/color'
import { fontSize, touchGuide } from '@/styles/mixin'

type Keyword = {
  name: string
  value: string | boolean
}

type Align = 'left' | 'center' | 'right' | 'start' | 'end'

interface KeywordButtonsProps {
  keywords?: Keyword[]
  isLoading?: boolean
  loadingItemSize?: number
  align?: Align
  onClick?: (value: string | boolean) => void
}

function KeywordButtons({
  keywords = [],
  isLoading,
  loadingItemSize,
  align = 'left',
  onClick,
}: KeywordButtonsProps) {
  const handleClickKeyword = useCallback(
    (value: string | boolean) => {
      onClick?.(value)
    },
    [onClick]
  )

  return (
    <Container
      align={align}
      className="recommend_keyword_list"
      data-cy="recommend_keyword_wrap"
    >
      {isLoading ? (
        <Skeleton size={loadingItemSize} />
      ) : (
        keywords.map(({ name, value }) => (
          <div key={value.toString()}>
            <KeywordButton onClick={() => handleClickKeyword(value)}>{name}</KeywordButton>
          </div>
        ))
      )}
    </Container>
  )
}

function Skeleton({ size = 4 }: { size?: number }) {
  return (
    <>
      {[...Array(size)].map((_, index) => (
        <div key={`KeywordButton-Skeleton${index}`}>
          <SkeletonItem
            width={100}
            height={32}
            borderRadius="50px"
          />
        </div>
      ))}
    </>
  )
}

const Container = styled.div<{ align: Align }>`
  display: flex;
  flex-wrap: wrap;
  margin: -6px -4px;

  & > * {
    padding: 6px 4px;
  }

  ${({ align }) => {
    const justifyContentMap = {
      left: 'flex-start',
      start: 'flex-start',
      center: 'center',
      right: 'flex-end',
      end: 'flex-end',
    }

    if (!justifyContentMap[align]) return ''

    return css`
      justify-content: ${justifyContentMap[align] || 'flex-start'};
    `
  }}
`

const KeywordButton = styled.button`
  position: relative;
  padding: 6px 12px;
  background-color: ${COLOR.gray.color.gray[200]};
  border-radius: 50px;
  color: ${COLOR.gray.color.gray[900]};
  ${({ theme }) => fontSize(theme, 14)}
  ${touchGuide({ borderRadius: '16px' })}
`

export default KeywordButtons
