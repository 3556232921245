import { css } from '@emotion/react'
import styled from '@emotion/styled'
import { useInfiniteQuery } from '@tanstack/react-query'
import { TmapApp } from '@tmap-web-lib/tmap-app-interface'
import { useRouter } from 'next/router'
import { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import {
  GetSearchArticlesResponse,
  SearchArticleItem,
  getSearchArticles,
} from '@/apis/helpcenter/getSearchArticles'
import Input from '@/components/Input'
import Header from '@/components/layout/Header'
import CircularSpinner from '@/components/loading/CircularSpinner'
import { QUERY_KEY } from '@/constants/key'
import { LOG_PAGE_ID } from '@/constants/log'
import useQueryStringController from '@/hooks/useQueryStringController'
import { sendEvent } from '@/libs/mixpanel'
import { bodyScrollLock } from '@/utils/bodyScrollLock'

import ListLoading from '../shared/components/ListLoading'
import SearchedContent from './SearchedContent'

interface SearchContainerProps {
  open?: boolean
}

const SearchContainer = forwardRef<HTMLInputElement, SearchContainerProps>(({ open }, ref) => {
  const router = useRouter()
  const layoutRef = useRef<HTMLDivElement>(null)
  const { removeQuery, updateQuery } = useQueryStringController()
  const { ref: viewRef, inView } = useInView()
  const inputRef = useRef<HTMLInputElement | null>(null)
  const keyword = (router.query.keyword ?? '') as string
  const [inputValue, setInputValue] = useState(keyword ?? '')

  const labelNames = TmapApp.env.isIOS ? 'i_os' : 'a_nd'
  const { data, hasNextPage, fetchNextPage, isFetchingNextPage, remove, isFetching } =
    useInfiniteQuery<GetSearchArticlesResponse>({
      queryKey: [QUERY_KEY.FAQ_SERACH_ARTICLES, keyword, labelNames],
      queryFn: ({ pageParam = 1 }) =>
        getSearchArticles({
          page: pageParam as number,
          per_page: 10,
          query: keyword,
          label_names: labelNames,
        }),
      getPreviousPageParam: () => {},
      getNextPageParam: (lastPage, allPages) => {
        return lastPage.page_count !== lastPage.page ? allPages.length + 1 : undefined
      },
      enabled: !!keyword.length,
    })

  const isExistArticleData = useMemo(() => data?.pages.length && data.pages[0].count > 0, [data])
  const isFirstLoading = useMemo(() => isFetching && !data, [isFetching, data])

  const searchData = useMemo(() => {
    return data?.pages.reduce((acc, cur) => [...acc, ...cur.results], [] as SearchArticleItem[])
  }, [data])

  const handleFocusInput = useCallback(() => {
    sendEvent(LOG_PAGE_ID.SEARCH, 'tap.searchbox')
  }, [])

  const onClose = useCallback(() => {
    removeQuery('keyword')
    remove()
  }, [removeQuery, remove])

  const handleClickBackButton = useCallback(() => {
    onClose()
  }, [onClose])

  const handleSearch = useCallback(() => {
    updateQuery({ keyword: inputValue })
    setTimeout(() => {
      inputRef.current?.blur()
    })
  }, [updateQuery, inputRef, inputValue])

  // body scroll lock
  useEffect(() => {
    if (open) {
      const restore = bodyScrollLock()

      return restore
    }

    return undefined
  }, [open])

  // infinite scroll next fetch
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView, hasNextPage, fetchNextPage])

  useEffect(() => {
    if (open) {
      // input value 초기화
      if (keyword.length) {
        setInputValue(keyword)
      } else {
        setInputValue('')
      }
    }
  }, [open, keyword])

  // Android back button event
  useEffect(() => {
    if (TmapApp.env.isInApp && TmapApp.env.isAndroid) {
      TmapApp.handleBackKeyEventFromWeb({ handleFromWeb: !!open })
    }

    return () => {
      if (TmapApp.env.isInApp && TmapApp.env.isAndroid) {
        TmapApp.handleBackKeyEventFromWeb({ handleFromWeb: false })
      }
    }
  }, [open])
  useEffect(() => {
    if (TmapApp.env.isInApp && TmapApp.env.isAndroid) {
      TmapApp.utils.addNativeEventListener('onHardwareBackKeyPressed', onClose)
    }
  }, [onClose])

  // 검색어(입력한 단어x, 검색 단어) 변경 시
  useEffect(() => {
    if (keyword && data) {
      sendEvent(LOG_PAGE_ID.SEARCH, 'tap.search', {
        user_query: keyword,
        searchresults: data?.pages[0].count ?? 0,
      })
    }
  }, [keyword, data])

  return (
    <Container
      ref={layoutRef}
      open={!!open}
    >
      <Header
        pageId={LOG_PAGE_ID.SEARCH}
        scrollRef={layoutRef}
        onClickBackButton={handleClickBackButton}
        containerStyle={open ? { top: 0 } : { top: 'auto', bottom: '100%' }}
      >
        <SearchArea>
          <Input
            ref={(e) => {
              if (ref) {
                if (typeof ref === 'function') {
                  ref(e)
                } else {
                  ref.current = e
                }
              }
              inputRef.current = e
            }}
            type="search"
            placeholder="단어로 검색하세요. 예) 운전점수"
            value={inputValue}
            clear
            onFocus={handleFocusInput}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            onSearch={handleSearch}
          />
        </SearchArea>
      </Header>
      {isFirstLoading ? (
        <SearchLoading>
          <CircularSpinner
            color="blue"
            size={48}
          />
        </SearchLoading>
      ) : (
        <SearchedContent
          count={data?.pages[0].count ?? 0}
          data={searchData}
        />
      )}
      {isExistArticleData && hasNextPage && (
        <ListLoading
          ref={viewRef}
          isLoading={isFetchingNextPage}
        />
      )}
    </Container>
  )
})
SearchContainer.displayName = 'SearchContainer'

const Container = styled.div<{ open: boolean }>`
  position: fixed;
  top: 9999px;
  top: 100vh;
  left: 0;
  right: 0;
  padding-top: 56px;
  max-height: 100vh;
  background-color: ${({ theme }) => theme.background};
  overflow-y: auto;
  z-index: ${({ theme }) => theme.zIndex.portalAbsolute};

  ${({ open }) =>
    open &&
    css`
      top: 0;
      bottom: 0;
    `}
`

const SearchArea = styled.div`
  padding: 8px 20px 8px 54px;
  width: 100%;
`

const SearchLoading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 100%;
  height: calc(100vh - 56px);
`

export default SearchContainer
